<template>
  <div>
    <upsale-offers-loader v-if="isLoading || !allCollectionsAreLoaded" />
    <div v-if="campaignOffers.length" class="global-relative white rounded py-12 px-4">
      <v-chip v-if="isTablet" class="feature-plank text-uppercase font-weight-medium text-body-2 px-2" color="primary" label>
        {{ $t('upsale.new_features') }}
      </v-chip>
      <div class="text-center text-body-2 mb-10">
        <div class="text-body-1 text-uppercase text-sm-h6 font-weight-bold mb-4 mb-sm-5">
          {{ $t('upsale.try_different_format') }}
        </div>
        <div class="font-weight-medium text-sm-body-1">
          {{ $t('upsale.choose_format') }}
        </div>
        {{ $t('upsale.make_creative') }}
      </div>
      <v-row class="flex-nowrap justify-space-between justify-md-center overflow-x-auto">
        <v-col v-if="!allCollectionsAreLoaded || isLoading" cols="3">
          <v-skeleton-loader type="image, list-item-three-line, card-heading" />
        </v-col>
        <v-col v-for="(campaign, index) in campaignOffers" :key="index" class="flex-grow-0">
          <upsale-offer-card :campaign="campaign" class="fill-height" />
        </v-col>
      </v-row>
    </div>
    <upsale-offers-empty v-if="offersCollectionIsEmpty" />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import {
    campaignsRepository,
    citiesRepository,
    regionsRepository,
    sitesRepository
  } from '@/services/repository-factory.js'
  import Campaign from '@/services/classes/Campaign.js'
  import handleErrors from '@/services/handleErrors.js'
  import UpsaleOfferCard from '@/views/Upsale/UpsaleOfferCard.vue'
  import UpsaleOffersEmpty from '@/views/Upsale/UpsaleOffers/UpsaleOffersEmpty.vue'
  import UpsaleOffersLoader from '@/views/Upsale/UpsaleOffers/UpsaleOffersLoader.vue'

  export default {
    name: 'UpsaleOffers',
    components: {
      UpsaleOffersLoader, UpsaleOffersEmpty, UpsaleOfferCard
    },
    props: {
      campaignId: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        campaignOffers: [],
        siteList: [],
        regionList: [],
        citiesList: [],
        isLoading: false
      }
    },
    computed: {
      ...mapState('settings', ['adFormats']),
      ...mapState('campaigns', ['campaignGroups', 'campaignGroupsAreLoading']),
      ...mapState('collections', [
        'countries',
        'languages',
        'trafficTypes',
        'categories',
        'interests',
        'ip2LocationTypes',
        'operatingSystems',
        'deviceVendors',
        'deviceModels',
        'browsers',
        'carriers',
        'categories',
        'categoryGroups',

        'countriesAreLoading',
        'languagesAreLoading',
        'trafficTypesAreLoading',
        'categoriesAreLoading',
        'interestsAreLoading',
        'ip2LocationTypesAreLoading',
        'operatingSystemsAreLoading',
        'deviceVendorsAreLoading',
        'deviceModelsAreLoading',
        'browsersAreLoading',
        'carriersAreLoading',
        'categoriesAreLoading',
        'categoryGroupsAreLoading'
      ]),
      allCollectionsAreLoaded() {
        const collections = [
          this.countriesAreLoading,
          this.languagesAreLoading,
          this.trafficTypesAreLoading,
          this.categoriesAreLoading,
          this.interestsAreLoading,
          this.ip2LocationTypesAreLoading,
          this.operatingSystemsAreLoading,
          this.deviceVendorsAreLoading,
          this.deviceModelsAreLoading,
          this.browsersAreLoading,
          this.carriersAreLoading,
          this.categoriesAreLoading,
          this.categoryGroupsAreLoading,
          this.campaignGroupsAreLoading
        ]
        return collections.every((collection) => !collection)
      },
      isDesktop() {
        return this.$vuetify.breakpoint.lgAndUp
      },
      isTablet() {
        return this.$vuetify.breakpoint.smAndUp
      },
      offersCollectionIsEmpty() {
        return this.allCollectionsAreLoaded && !this.campaignOffers.length && !this.isLoading
      }
    },
    watch: {
      allCollectionsAreLoaded: {
        async handler() {
          await this.fetchCampaignOffers()
        }
      }
    },
    created() {
      this.fetchCampaignGroups()
      this.fetchCollectionsForCampaignForm()
      this.fetchCampaignOffers()
    },
    methods: {
      ...mapActions('collections', ['fetchCollectionsForCampaignForm']),
      ...mapActions('campaigns', ['fetchCampaignGroups']),
      fetchSitesByIds(siteIds) {
        return siteIds.length ? sitesRepository.searchById({ site_ids: siteIds }) : []
      },
      fetchRegions(id) {
        return regionsRepository.find({ regionsIds: id })
      },
      fetchCities(params) {
        return citiesRepository.find(params)
      },
      adFormatById(id) {
        return this.adFormats?.find((format) => format.id === id)
      },
      async fetchCampaignOffers() {
        if (!this.allCollectionsAreLoaded && !this.campaignOffers.length) return

        try {
          this.isLoading = true
          const campaignOffersResponse = await campaignsRepository.offers(this.campaignId)
          this.siteList = await Promise.all(
            campaignOffersResponse.map(async (offer) => (offer.params.targets?.site_target
              ? this.fetchSitesByIds(offer.params.targets.site_target.site_ids)
              : []))
          )
          this.regionList = await Promise.all(
            campaignOffersResponse.map(async (offer) => (offer.params.targets?.region_target
              ? this.fetchRegions(offer.params.targets.region_target.region_ids)
              : []))
          )
          this.citiesList = await Promise.all(
            campaignOffersResponse.map(async (offer) => (
              offer.params.targets?.region_target && offer.params.targets?.country_target
                ? this.fetchCities({
                  regionsIds: offer.params.targets.region_target?.region_ids,
                  countriesIds: offer.params.targets.country_target?.country_ids
                })
                : []))
          )
          this.campaignOffers = campaignOffersResponse?.map((offer, index) => {
            const collections = {
              countries: this.countries,
              languages: this.languages,
              trafficTypes: this.trafficTypes,
              interests: this.interests,
              ip2LocationTypes: this.ip2LocationTypes,
              operatingSystems: this.operatingSystems,
              deviceVendors: this.deviceVendors,
              deviceModels: this.deviceModels,
              browsers: this.browsers,
              carriers: this.carriers,
              categories: this.categories,
              categoryGroups: this.categoryGroups,
              campaignGroups: this.campaignGroups,
              ...this.siteList.length && {
                sites: this.siteList[index]
              },
              ...this.regionList.length && {
                regions: this.regionList[index]
              },
              ...this.citiesList.length && {
                cities: this.citiesList[index]
              }
            }
            const adFormat = {
              ad_format: this.adFormatById(offer.params.ad_format_id)
            }
            const convertedOfferCampaign = Campaign.getOfferCampaign(offer.params, collections)
            return new Campaign({ ...convertedOfferCampaign, ...adFormat })
          })
          this.isLoading = false
        } catch (error) {
          handleErrors(error)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .feature-plank {
    position: absolute;
    width: auto;
    top: 12px;
    left: 12px;
  }
</style>
